<template>
  <section>
    <b-form>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8">
          <section class="w-full flex flex-col">
            <div class="w-full flex gap-5 justify-between mb-2">
              <input-required
              class="col-9 padding-0"
                :label="$t('sectionPharmaceuticals.description')"
                v-model="value.Descricao"
                :rules="{required:true, max:50}"
                max="50"
              />
              <div class="col-span-12 lg:col-span-5">
                  <CheckboxDefault
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.inativarForma'
                        )
                      "
                      class="justify-end"
                      v-model="value.InativarForma"
                    />
                </div>
            </div>

            <div
              class="w-full flex border__top border__bottom"
              style="font-size: small;"
            >
              <div class="flex col-4 padding-0">
                <div
                class=" 2xl:w-20 p-2 col-3 padding-0 border__right"
                :class="{
                }"
              >
                <span class="text-red-500 required">{{
                  $t("sectionPharmaceuticals.type")
                }}<span style="color:#cf0209"> *</span></span>
              </div>
                <div class="col-9 padding-right-0 border__right">
                    <radioButton :options="options" :label="$t('sectionPharmaceuticals.type')" rules="required" v-model="value.TipoForma" stacked />
                </div>
              </div>
             <div class="col-8 padding-0">
              <div
                class="flex-1 flex flex-col col-12 padding-0 border__right"
                :class="{
                  border__right: $mq.desktop,
                }"
              >
                <div class="flex flex-col p-2 border__bottom gap-1 ">
                  <CheckboxDefault
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.selectSuggestedQuantity'
                      )
                    "
                    v-model="value.SelecionaQtdeSugerida"
                  />
                  <CheckboxDefault
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.multiplyComposition'
                      )
                    "
                    v-model="value.MultiplicaComposicao"
                    :disabled="value.TipoForma == '5' ||
                                 value.TipoForma == '6'?
                                 false : true"
                      :value="value.TipoForma == '5' ||
                              value.TipoForma == '6'?
                                 value.MultiplicaComposicao : value.MultiplicaComposicao =  false"
                  />
                </div>
                <div class="flex flex-col p-2 border__bottom gap-1" >
                  <CheckboxDefault
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.liquidHomeopathy'
                      )
                    "
                    v-model="value.HomeopatiaLiquida"
                    :disabled="value.TipoForma == '2' ?
                                 false : true"
                      :value="value.TipoForma == '2' ?
                                 value.HomeopatiaLiquida : value.HomeopatiaLiquida =  false"
                  />
                  <CheckboxDefault
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.doNotDeductFromTheVehicle'
                      )
                    "
                     v-model="value.DeduzirQtdeVeiculo"
                     :disabled="value.TipoForma == '2' ?
                                 false : true"
                      :value="value.TipoForma == '2' ?
                                 value.DeduzirQtdeVeiculo : value.DeduzirQtdeVeiculo =  false"
                  />
                  
                </div>
                <div class="flex flex-col p-2 border__bottom">
                  <CheckboxDefault
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.convertPackageVolume'
                      )
                    "
                       v-model="value.ConverteVolumeEmbalagem"
                  />
                </div>
              </div>
              <div
                class="w-full xl:w-auto p-2 border__right border__bottom"
                :class="{
                }"
              >
                <span class="text-red-500">
                  Cálculo da embalagem 
                  <span v-if="value.TipoForma == '5' || value.TipoForma == '6'">
                      *
                  </span>
              </span>
              </div>
              <div class="flex flex-col p-2 FormsRadio border__right">
                <radioButton :options="optionsCalculoEmbalagem"
                  label="Cálculo da embalagem"
                  v-model="value.CalculoEmbalagem"
                  stacked 
                  :rules="{required : value.TipoForma == '5' ||
                                      value.TipoForma == '6'}"
                  :disabled="value.TipoForma == '5' ||
                            value.TipoForma == '6'?
                            false : true"
                  :value="value.TipoForma == '5' ||
                          value.TipoForma == '6'?
                          value.CalculoEmbalagem : value.CalculoEmbalagem =  null"
                   />
              </div>
             </div>
            </div>

            <div
              class="w-full flex border__bottom"
              :class="{
              }"
            >
              <div
                class="2xl:w-1/2  p-2 col-6 "
                style="padding-left: 0!important;"
                :class="{
                  border__bottom: $mq.notebook,
                }"
              >
                <input-default
                  :label="
                    $t('sectionPharmaceuticals.generalPharmaceuticals.use')
                  "
                  v-model="value.Uso"
                  :rules="{max:20}"
                  max="20"
                />
              </div>
              <div class="w-1/2 flex p-2 FormsRadio border__right">
                <div class="border__right w-full 2xl:w-60 text-red-500 col-5 mb-2 text-center  flex 2xl:items-start">
                  <span
                  >{{
                    $t(
                      "sectionPharmaceuticals.generalPharmaceuticals.typeOfUse"
                    )
                  }} *</span
                >
                </div>
                <radioButton :options="optionsTipoUso"
                              label="Tipo Uso"
                              v-model="value.TipoUso"
                              stacked 
                              class="col-7"
                              rules="required"
                   />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-6">
                    <input-default label="P.O.P" 
                                  v-model="value.ProcedimentoOperacionalPadrao" 
                                  :rules="{max:10}" mask="XXXXXXXXXX"
                                  max="10"/>
                  </div>
                  <div class="col-span-12 lg:col-span-6 ">
                    <CheckboxDefault
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.printProductAnalysisFields'
                        )"
                      class="justify-end"
                      v-model="value.ImprimeCamposAnaliseProduto"
                    />
                    <CheckboxDefault
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.SelecionarVolumeAutomatico'
                        )"
                      class="justify-end"
                      v-model="value.SelecionaVolumeAutomatico"
                      :disabled="value.TipoForma == '5' ||
                                 value.TipoForma == '6'?
                                 false : true"
                      :value="value.TipoForma == '5' ||
                              value.TipoForma == '6'?
                                 value.SelecionaVolumeAutomatico : value.SelecionaVolumeAutomatico =  false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="grid justify-between grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-5">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.validityDays'
                        )
                      "
                      :hasText="true"
                      text="(dias)"
                      type="number"
                      mask="XXXX"
                      :rules="{positive:true,integer:true}"
                      v-model="value.Validade"
                    />
                  </div>
                  <div class="col-span-12 lg:col-span-5">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.quantityMlDrops'
                        )
                      "
                      :hasText="true"
                      text="(ML/Gotas)"
                      v-model="value.MlGotas"
                      :rules="{positive:true ,decimal:[10,2], max:8}"
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-7">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.FatorPerda'
                        )
                      "
                      class="justify-end"
                       type="number"
                       :rules="{positive:true,decimal:[24,5],max:24}"
                      v-model="value.FatorPerda" 
                      :disabled="value.TipoForma == null ||
                                 value.TipoForma == '2' ||
                                 value.TipoForma == '3' ||
                                 value.TipoForma == '4' || 
                                 value.TipoForma == '7'?
                                 true : false"
                      :value="value.TipoForma == null ||
                              value.TipoForma == '2' ||
                              value.TipoForma == '3' ||
                              value.TipoForma == '4' || 
                              value.TipoForma == '7'?
                              value.FatorPerda = null : value.FatorPerda"
                    />
                  </div>
                  <div class="col-span-12 lg:col-span-5">
                  <CheckboxDefault
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.ImprimirUnidadeRotulo'
                        )
                      "
                      class="justify-end"
                      v-model="value.imprimirUnidadeRotulo"
                      :disabled="value.TipoForma == '3' ?
                                 false : true"
                      :value="value.TipoForma == '3' ?
                                 value.imprimirUnidadeRotulo : value.imprimirUnidadeRotulo =  false"
                    />
                    <CheckboxDefault
                        :label="
                          $t(
                            'sectionPharmaceuticals.generalPharmaceuticals.AplicaFatorPerdaQSP'
                          )
                        "
                        class="justify-end"
                        v-model="value.AtivaFatorPerdaQSP"
                        :disabled="value.TipoForma == '2' ||
                                 value.TipoForma == '3' ||
                                 value.TipoForma == '4' || 
                                 value.TipoForma == '7'?
                                 true : false"
                      :value="value.TipoForma == '2' ||
                              value.TipoForma == '3' ||
                              value.TipoForma == '4' || 
                              value.TipoForma == '7'?
                              value.AtivaFatorPerdaQSP = null : value.AtivaFatorPerdaQSP"
                      />
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-8">
                    <Dropdown 
                        :Items="dropdownItemsManipulador"
                        :valueDefault="$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator')"
                        :label="$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator')"
                        v-model="value.ManipuladorID ">
                    </Dropdown>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.hourFormulas'
                        )
                      "
                      v-model="value.QuantidadeFormulasHora"
                      type="number"
                      :rules="{positive:true,integer:true}"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-8">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.labelDescription'
                        )
                      "
                      :rules="{max:50}"
                      max="50"
                      v-model="value.DescricaoRotulo"
                      :disabled="value.TipoForma == '2' ||
                                 value.TipoForma == '3' ||
                                 value.TipoForma == '7' ?
                                 false : true"
                      :value="value.TipoForma == '2' ||
                                 value.TipoForma == '3' ||
                                 value.TipoForma == '7' ?
                                 value.DescricaoRotulo : value.DescricaoRotulo =  null"
                    />
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <input-default
                      :label="
                        $t(
                          'sectionPharmaceuticals.generalPharmaceuticals.minimumQsp'
                        )
                      "
                      :hasText="true"
                      text="%"
                      type="number"
                      :rules="{positive:true,decimal:[24,5]}"
                      v-model="value.PercentualQspMinimo"
                      :disabled="value.TipoForma == '5' ||
                                 value.TipoForma == '6'?
                                 false : true"
                      :value="value.TipoForma == '5' ||
                              value.TipoForma == '6'?
                                 value.PercentualQspMinimo : value.PercentualQspMinimo =  null"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="grid grid-cols-12 gap-4">
                  <div class="col-span-12 lg:col-span-6">
                      <Dropdown 
                        :Items="dropdownItemsVeiculo"
                        :valueDefault="$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle')"
                        :label="$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle')"
                        v-model="value.VeiculoID"
                        :disabled="value.TipoForma == '2' ||
                                  value.TipoForma == '3' ||
                                  value.TipoForma == '7' ?
                                  false : true"
                        :value="value.TipoForma == '2' ||
                                  value.TipoForma == '3' ||
                                  value.TipoForma == '7' ?
                                  value.Veiculo : value.Veiculo =  null"
                        >
                    </Dropdown>
                  </div>
                  <div class="col-span-12 lg:col-span-6">
                    <div class="flex flex-col lg:pl-5">
                      <CheckboxDefault
                        :label="
                          $t(
                            'sectionPharmaceuticals.generalPharmaceuticals.formMonitoredWeighing'
                          )
                        "
                        class="justify-end"
                        v-model="value.AtivaPesagemForma"
                      />
                      <CheckboxDefault
                        :label="
                          $t(
                            'sectionPharmaceuticals.generalPharmaceuticals.calculateDensity'
                          )
                        "
                        class="justify-end"
                        v-model="value.CalcularDensidade"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 lg:col-span-6">
              <div>
                <input-default
                    :label="
                      $t(
                        'sectionPharmaceuticals.generalPharmaceuticals.CodLab'
                      )
                    "
                    :rules="{max:2}"
                    max="2"
                    v-model="value.LabEspCustos"
                  />
              </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <div class="w-80 flex flex-col" :class="{ 'w-full': $mq.mobile }">
            <div
              class="imagePreviewWrapper background__color button__border__bottom h-72 rounded relative"
              :style="{ 'background-image': `url(${previwImage})` }"
            >
              <button
                class="absolute bottom-1 -right-1"
                @click.prevent="clearImagePreview"
              >
                <TrashIcon class="w-12" />
              </button>
            </div>
            <div class="flex flex-col">
              <input
                class="hidden"
                ref="fileInput"
                type="file"
                @input="pickFile"
              />
              <button
                class="background__color w-full h-12 rounded font-bold text-base button__border__bottom"
                @click.prevent="selectImage"
              >
                {{
                  $t(
                    "sectionPharmaceuticals.generalPharmaceuticals.chooseAnImage"
                  )
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import radioButton from "@core/components/commons/radioButton/radioButton.vue";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import TrashIcon from "@/assets/img/excluir.svg";

import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
    InputRequired,
    InputDefault,
    CheckboxDefault,
    TrashIcon,
    radioButton,
    Dropdown,
  },
  props: {
    value: {
      required: true,
      type:Object,
    },
    previwImage: {
      required:false,
    }
  },
  data() {
    return {
      dropdownItemsManipulador:[],
      dropdownItemsVeiculo:[],
      required,
      options: [
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.capsule'), value: "0" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.volume'), value: "1" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.homeopathy'), value: "2" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.floral'), value: "3" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.unitary'), value:"4" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.volumeAmount'), value: "5" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.volumeAmountMg'), value: "6" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.paper'), value: "7" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.implant'), value: "8" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.comprimido'), value: "9" },
      ],
      optionsCalculoEmbalagem: [
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.unitVolume'), value: "0" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.totalVolume'), value: "1" },
      ],
      optionsTipoUso: [
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.systemic'), value: "0" },
        { text: this.$t('sectionPharmaceuticals.generalPharmaceuticals.topic'), value: "1" },
      ],
    };
  },
  created () {
    this.ObterSeletorManipulador();
    this.ObterSeletorVeiculo();
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previwImage = e.target.result;
        };
        reader.readAsDataURL(file);
        this.$emit('imagem',file)
      }
    },
    clearImagePreview () {
      this.$emit('imagem',null)
      this.previwImage = null;
    },
    ObterSeletorManipulador() {
      this.$http({
        url: `/funcionario/obter-seletor-funcionario`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsManipulador = res.data;
        })
    },
    ObterSeletorVeiculo() {
      this.$http({
        url: `/produto/obter-list`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsVeiculo = res.data;
        })
    },
  },
};
</script>

<style scoped lang="scss">
.background__color {
  background-color: #efefef;
}

.flex__direction {
  flex-direction: column;
}

.border__top {
  border-top: 1px solid rgba(200, 200, 200, 0.5);
}

.border__right {
  border-right: 1px solid rgba(200, 200, 200, 0.5);
}

.border__bottom {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}

.button__border__bottom {
  border-bottom: 3px solid black;
}

.margin {
  margin: 0 !important;
  padding: 0 !important;
}

.imagePreviewWrapper {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 0 1rem 0;
}
.padding-0{
  padding: 0!important;
}
.padding-right-0{
  padding-right:0!important;
}
</style>
