<template>
  <section>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
      <div class="col-span-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <input-default
            :label="
                  $t('sectionPharmaceuticals.valuePharmaceuticals.minimumValue')
                "
                v-model="value.ValorMinimoForma"
                type="number"
                :rules="{positive:true,decimal:[24,5]}"
                />
              </div>
              <div class="col-span-12">
                <input-default
                :label="
                  $t(
                    'sectionPharmaceuticals.valuePharmaceuticals.additionalCharge'
                    )
                "
                type="number"
                :rules="{positive:true,decimal:[24,5]}"
                v-model="value.CustoAdicionalForma"
                />
              </div>
            </div>
          </div>
        </div>
        
        <ValidationObserver  ref="observer" v-slot="{ validate  }">
        <b-form>
        <div
        class="w-full flex flex-col lg:flex-row items-start border__top border__bottom my-3"
        >
        <div
          class="w-full lg:w-1/2 lg:pr-4"
          :class="{
            border__right: $mq.desktop,
          }"
        >
          <div class="w-full flex flex-col py-4" >
            <div class="flex w-full" style="justify-content: space-between;">
              <h3 class="text-left text-red-500 text-lg">
                {{
                  $t(
                    "sectionPharmaceuticals.valuePharmaceuticals.rangeMinimumValue"
                  )
                }}
               
              </h3>
              <b-button
                  class="text-base text-white "
                  style="background-color:#dc3545; opacity:1!important; border-color: #dc3545;"
                  :disabled="value.TipoForma == '0' ||
                             value.TipoForma == '1' ||
                             value.TipoForma == '8' ? false : true"
                  @click="Adicionar('faixa')"
                >
                  Incluir
                </b-button>
            </div>
            <div
              class="w-full flex gap-3"
              :class="{
                flex__direction: $mq.mobile,
              }"
            >
              <Table :columns="tableQtdeInicialColumns">
                <tr v-for="row in value.FormaFarmaceuticaFaixa" :key="row" v-show="row.excluido != true">
                  <td>
                    <div v-if="edit != row">
                        {{ row.quantidadeInicial }}
                    </div>
                    <div v-else>
                      <input-required
                      label="Inicial"
                      type="number"
                      v-model="QndtInicialEdit"
                      :rules="{positive:true,double:true,required:true,maiorQ:QndtFinalEdit}"
                      />
                    </div>
                  </td>
                  <td>
                    <div v-if="edit != row">
                      {{ row.quantidadeFinal }}
                    </div>
                    <div v-else>
                      <input-required
                      :rules="{positive:true,double:true,required:true}"
                      label="Final"
                      type="number"
                      v-model="QndtFinalEdit"
                      />
                    </div>  
                  </td>
                  <td>
                    <div v-if="edit != row">
                      {{ row.valorMinimo }}
                    </div>
                    <div v-else>
                      <input-required
                      :rules="{positive:true,double:true,required:true}"
                      label="Valor"
                      type="number"
                      v-model="ValorMinimoEdit"
                      />
                    </div>  
                  </td>
                  <td>
                    <template>
                      <div class="flex justify-center">
                        <b-button
                          class="bg-transparent border-0"
                          size="sm"
                          v-if="edit != row"
                          @click="editar(row,'faixa')"
                        >
                          <EditIcon class="w-4 icon__actions" />
                        </b-button>
                        <b-button
                          class="border-0"
                          variant="success"
                          size="sm"
                          @click="validate(salvar(row,'faixa'))"
                          v-if="edit == row"
                        >
                        Salvar
                        </b-button>
                        <b-button
                          variant="danger"
                          style="margin-left: 0.5rem;"
                          size="sm"
                          @click="cancelar('faixa')"
                          v-if="edit == row"
                        >
                        cancelar
                        </b-button>
                        <b-button
                          class="bg-transparent border-0"
                          size="sm"
                          v-if="edit != row"
                          @click="deletar(row,'faixa')"
                        >
                          <DeleteIcon class="w-4  icon__actions" />
                        </b-button>
                      </div>
                    </template>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pl-4">
          <div class="w-full flex flex-col py-4">
            <div class="flex w-full" style="justify-content: space-between;">
            <h3 class="text-left text-red-500 text-lg">
              {{
                $t(
                  "sectionPharmaceuticals.valuePharmaceuticals.marginValueShape"
                )
              }}
            </h3>
            <b-button
                  class="text-base text-white "
                  style="background-color:#dc3545; opacity:1!important; border-color: #dc3545;"
                  @click="Adicionar('margem')"
                >
                  Incluir
                </b-button>
            </div>
            <div
              class="w-full  gap-3"
              :class="{
                flex__direction: $mq.mobile,
              }"
            >
            <Table :columns="tableValorInicialColumns">
              <tr v-for="row in value.FormaFarmaceuticaMargem" :key="row" v-show="row.excluido != true">
               <td>
                 <div v-if="edit != row">
                     {{ row.valorInicial }}
                 </div>
                 <div v-else>
                   <input-required
                   :rules="{positive:true,double:true,required:true,maiorQ:QndtFinalEdit}"
                   label="Inicial"
                   type="number"
                   v-model="QndtInicialEdit"
                   />
                 </div>
               </td>
               <td>
                 <div v-if="edit != row">
                   {{ row.valorFinal }}
                 </div>
                 <div v-else>
                   <input-required
                   :rules="{positive:true,double:true,required:true}"
                   label="Final"
                   type="number"
                   v-model="QndtFinalEdit"
                   />
                 </div>  
               </td>
               <td>
                 <div v-if="edit != row">
                   {{ row.percentualMargem }}
                 </div>
                 <div v-else>
                   <input-required
                   :rules="{positive:true,double:true,required:true}"
                   label="Margem"
                   type="number"
                   v-model="ValorMinimoEdit"
                   />
                 </div>  
               </td>
               <td>
                 <template>
                   <div class="flex justify-center">
                     <b-button
                       class="bg-transparent border-0"
                       size="sm"
                       v-if="edit != row"
                       @click="editar(row,'margem')"
                     >
                       <EditIcon class="w-4 icon__actions" />
                     </b-button>
                     <b-button
                       class="border-0"
                       variant="success"
                       size="sm"
                       @click="validate(salvar(row,'margem'))"
                       v-if="edit == row"
                     >
                     Salvar
                     </b-button>
                     <b-button
                       variant="danger"
                       style="margin-left: 0.5rem;"
                       size="sm"
                       @click="cancelar('margem')"
                       v-if="edit == row"
                     >
                     cancelar
                     </b-button>
                     <b-button
                       class="bg-transparent border-0"
                       size="sm"
                       v-if="edit != row"
                       @click="deletar(row,'margem')"
                     >
                       <DeleteIcon class="w-4  icon__actions" />
                     </b-button>
                   </div>
                 </template>
               </td>
              </tr>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </ValidationObserver>

      <div class="w-full lg:w-1/2">
        <Dropdown 
          :Items="dropdownItemsNcm"
          valueDefault="NCM/SH"
          label="NCM/SH"
          v-model="value.NcmID">
        </Dropdown>
      </div>

  </section>
</template>

<script>
import { required } from "@validations";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import InputRequired from "@core/components/commons/inputs/InputRequired";
import Table from "@core/components/commons/table/Table";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
import { ValidationObserver } from "vee-validate";

import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";
import { BForm,BButton } from "bootstrap-vue";

export default {
  components: {
    BForm,
    BButton,
    ValidationObserver,
    Table,
    Dropdown,
    InputRequired,
    EditIcon,
    DeleteIcon,
    InputDefault,
  },
  props: {
    value: {
      required: true,
      type:Object,
    }
  },
  data() {
    return {
      tableQtdeInicialColumns: ["Quant. inicial","Quant. final","Valor mínimo","Ações"],
      tableValorInicialColumns: ["Valor inicial","Valor final","Margem %","Ações"],
      required,
      edit:null,
      dropdownItemsNcm: null,
      ValorMinimoEdit: null,
      QndtFinalEdit:null,
      QndtInicialEdit:null,
    };
  },
  created () {
    this.ObterSeletorNcm();
  },
  methods: {
    ObterSeletorNcm() {
      this.$http({
        url: `/ncm/obter-seletor-ncm`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsNcm = res.data;
        })
    },
    editar (item, tipo) {
      if (tipo == 'faixa') {
        this.edit = item;
        this.QndtFinalEdit = item.quantidadeFinal;
        this.ValorMinimoEdit = item.valorMinimo;
        this.QndtInicialEdit = item.quantidadeInicial;
      } else {
        this.edit = item;
        this.QndtFinalEdit = item.valorFinal;
        this.ValorMinimoEdit = item.percentualMargem;
        this.QndtInicialEdit = item.valorInicial;
      }
    },
    async salvar (item, tipo) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return

      if (tipo == 'faixa') {
        if (item.id != null) {
          this.$set(this.value.FormaFarmaceuticaFaixa,
                this.value.FormaFarmaceuticaFaixa.indexOf(item),
                {
                  quantidadeInicial: this.QndtInicialEdit,
                  quantidadeFinal: this.QndtFinalEdit,
                  valorMinimo: this.ValorMinimoEdit,
                  id: item.id
                })
        } else {
          this.$set(this.value.FormaFarmaceuticaFaixa,
                this.value.FormaFarmaceuticaFaixa.indexOf(item),
                {
                  quantidadeInicial: this.QndtInicialEdit,
                  quantidadeFinal: this.QndtFinalEdit,
                  valorMinimo: this.ValorMinimoEdit,
                })
          }
      } else {
        if (item.id != null) {
          this.$set(this.value.FormaFarmaceuticaMargem,
            this.value.FormaFarmaceuticaMargem.indexOf(item),
            {
              valorInicial: this.QndtInicialEdit,
              valorFinal: this.QndtFinalEdit,
              percentualMargem: this.ValorMinimoEdit,
              id: item.id
            })
        } else {
          this.$set(this.value.FormaFarmaceuticaMargem,
            this.value.FormaFarmaceuticaMargem.indexOf(item),
            {
              valorInicial: this.QndtInicialEdit,
              valorFinal: this.QndtFinalEdit,
              percentualMargem: this.ValorMinimoEdit,
            })
        }
      }
      this.edit = null;
      this.QndtFinalEdit = null;
      this.ValorMinimoEdit = null;
      this.QndtInicialEdit = null;
    },
    deletar (item, tipo) {
      if (tipo == 'faixa') {
        if (item.id != null) {
          this.$set(this.value.FormaFarmaceuticaFaixa,
                this.value.FormaFarmaceuticaFaixa.indexOf(item),
                {
                  quantidadeInicial: item.quantidadeInicial,
                  quantidadeFinal: item.quantidadeFinal,
                  valorMinimo: item.valorMinimo,
                  id: item.id,
                  excluido: true
                })
        } else {
            this.value.FormaFarmaceuticaFaixa.splice(this.value.FormaFarmaceuticaFaixa.indexOf(item), 1);
          }
      } else {
        if (item.id != null) {
          this.$set(this.value.FormaFarmaceuticaMargem,
            this.value.FormaFarmaceuticaMargem.indexOf(item),
            {
              valorInicial: item.valorInicial,
              valorFinal: item.valorFinal,
              percentualMargem: item.percentualMargem,
              id: item.id,
              excluido: true
            })
        } else {
          this.value.FormaFarmaceuticaMargem.splice(this.value.FormaFarmaceuticaFaixa.indexOf(item), 1);
        }
      }
      this.edit = null;
      this.QndtFinalEdit = null;
      this.ValorMinimoEdit = null;
      this.QndtInicialEdit = null;
    },
    Adicionar (tipo) {
      var existeVazio = this.value.FormaFarmaceuticaFaixa?.filter(x => x.quantidadeInicial == null ||
                                                                        x.quantidadeInicial == "" ||
                                                                        x.quantidadeFinal == "" ||
                                                                        x.quantidadeFinal == null ||
                                                                        x.valorMinimo == "" ||
                                                                        x.valorMinimo == null
      )
      var existeMargemVazio = this.value.FormaFarmaceuticaMargem?.filter(x => x.valorInicial == null ||
                                                                              x.valorInicial == "" ||
                                                                              x.valorFinal == "" ||
                                                                              x.valorFinal == null ||
                                                                              x.percentualMargem == "" ||
                                                                              x.percentualMargem == null)
      if (tipo == 'faixa')
      {
        if (existeMargemVazio.length > 0) 
          this.value.FormaFarmaceuticaMargem.splice(this.value.FormaFarmaceuticaMargem.indexOf(existeMargemVazio[0]), 1)
        
        if (existeVazio.length == 0) {
          var novo = this.value.FormaFarmaceuticaFaixa.push({ quantidadeInicial: null, quantidadeFinal: null, valorMinimo: null })
          this.edit = this.value.FormaFarmaceuticaFaixa[novo - 1];
          return;
        }
        this.edit = existeVazio[0];
      } else {
        if (existeVazio.length > 0) 
        this.value.FormaFarmaceuticaFaixa.splice(this.value.FormaFarmaceuticaFaixa.indexOf(existeVazio[0]), 1)
        
        if (existeMargemVazio.length == 0) {
          var novaMargem = this.value.FormaFarmaceuticaMargem.push({ valorInicial: null, valorFinal: null, percentualMargem: null })
          this.edit = this.value.FormaFarmaceuticaMargem[novaMargem - 1];
          return;
        }
        this.edit = existeMargemVazio[0];
      }
    },
    cancelar (tipo) {
      if (tipo == 'faixa') {
        var existeVazio = this.value.FormaFarmaceuticaFaixa?.filter(x => x.quantidadeInicial == null ||
        x.quantidadeInicial == "" ||
        x.quantidadeFinal == "" ||
        x.quantidadeFinal == null ||
        x.valorMinimo == "" ||
        x.valorMinimo == null
        )
        if (existeVazio.length > 0) {
          this.value.FormaFarmaceuticaFaixa.splice(this.value.FormaFarmaceuticaFaixa.indexOf(existeVazio[0]),1)
        }
      } else {
        var existeMargemVazio = this.value.FormaFarmaceuticaMargem?.filter(x =>x.valorInicial == null ||
                                                                    x.valorInicial == "" ||
                                                                    x.valorFinal == "" ||
                                                                    x.valorFinal == null ||
                                                                    x.percentualMargem == "" ||
                                                                    x.percentualMargem == null
        )
        if (existeMargemVazio.length > 0) {
          this.value.FormaFarmaceuticaMargem.splice(this.value.FormaFarmaceuticaMargem.indexOf(existeMargemVazio[0]),1)
        }
      }
      this.edit = null;
      this.QndtFinalEdit = null;
      this.ValorMinimoEdit = null;
      this.QndtInicialEdit = null;
    },
  }
};
</script>

<style scoped>
.border__top {
  border-top: 1px solid rgba(200, 200, 200, 0.5);
}

.border__right {
  border-right: 1px solid rgba(200, 200, 200, 0.5);
}

.border__bottom {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}

.flex__direction {
  flex-direction: column;
}
</style>
