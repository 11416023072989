<template>
  <ValidationObserver ref="observer" v-slot="{ validate  }">
    <b-form @submit.stop.prevent="validate().then(submit)">
    <div class="w-full flex flex-col justify-between">
      <section class="-mt-4">
        <b-tabs class="w-full" v-model="tabIndexComponent">
          <b-tab title="Geral">
            <GeneralPharmaceuticalsInformation @imagem="Imagem = $event" 
                                               v-model="viewModel" 
                                               :previwImage="previwImage"
                                               class="mt-4" />
          </b-tab>
          <b-tab title="Ensaios">
            <EssaysInformationPharmaceuticals
              class="mt-4"
              :showFormEdit="showFormEdit"
              @showForm="(event) => $emit('showForm',event)"
              v-model="viewModel"
            />
          </b-tab>
          <b-tab title="Valores">
            <ValuePharmaceuticalsInformation    v-model="viewModel" class="mt-4" />
          </b-tab>
        </b-tabs>
      </section>
      <div class="flex">
      <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
    </div>
      <footer
        v-if="tabIndexComponent !== 1"
        class="w-full flex items-center justify-start gap-5 my-10"
      >
        <Button
          class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
          buttonText="Confirmar"
          type="submit"
        />
        <Button
          class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
          buttonText="Cancelar"
          :onClick="() => $router.push('/farmaceuticas')"
        />
      </footer>
      <Modal
        v-if="modalIsOpen"
        :modalIsOpen.sync="modalIsOpen"
        :closeModal="closeModal"
        :modalText="$t('commons.modalTextEdit')"
      />
      <Modal
        v-if="modalError"
        :modalIsOpen.sync="modalError"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErrorCreate')"
      />
      <Modal
        v-if="modalErroSalvar"
        :modalIsOpen.sync="modalErroSalvar"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErroSalvar')"
      />
    </div>
  </b-form>
  </ValidationObserver>
  </template>
  
  <script>
  import { BTabs, BTab } from "bootstrap-vue";
  
  import GeneralPharmaceuticalsInformation from "./GeneralPharmaceuticalsInformation";
  import EssaysInformationPharmaceuticals from "./EssaysInformationPharmaceuticals";
  import ValuePharmaceuticalsInformation from "./ValuePharmaceuticalsInformation";
  import Button from "@core/components/commons/button/Button";
  import Modal from "@core/components/commons/modal/Modal";
  import { BForm } from "bootstrap-vue";
  import { ValidationObserver } from "vee-validate";
  
  export default {
    components: {
      BTabs,
      BTab,
      BForm,
      GeneralPharmaceuticalsInformation,
      EssaysInformationPharmaceuticals,
      ValuePharmaceuticalsInformation,
      Button,
      Modal,
      ValidationObserver
    },
    props: {
      tabIndex: {
        type: Number,
      },
      showFormEdit: {
        type: Boolean,
      },
  },
  created() {
    this.RecuperarForma(this.$router.currentRoute.params.id);
  },
    data() {
      return {
        modalErroSalvar: null,
        modalError:false,
        modalIsOpen: false,
        tabIndexComponent: this.tabIndex,
        Imagem: null,
        previwImage:null,
        viewModel: {
          id:null,
          TipoForma: null,
          Descricao: null,
          SelecionaQtdeSugerida: false,
          MultiplicaComposicao:false,
          HomeopatiaLiquida:false,
          DeduzirQtdeVeiculo:false,
          ConverteVolumeEmbalagem: false,
          CalculoEmbalagem: null,
          Uso: null,
          TipoUso: null,
          ProcedimentoOperacionalPadrao: null,
          ImprimeCamposAnaliseProduto: false,
          Validade: null,
          MlGotas: null,
          ManipuladorID: null,
          QuantidadeFormulasHora: null,
          FatorPerda:null,
          DescricaoRotulo: null,
          PercentualQspMinimo: null,
          VeiculoID: null,
          AtivaPesagemForma: false,
          CalcularDensidade: false,
          imprimirUnidadeRotulo: false,
          SelecionaVolumeAutomatico:false,
          AtivaFatorPerdaQSP: false,
          ValorMinimoForma: null,
          InativarForma: false,
          LabEspCustos:null,
          CustoAdicionalForma: null,
          NcmID: null,
          FormaFarmaceuticaEnsaio: [],
          FormaFarmaceuticaFaixa: [],
          FormaFarmaceuticaMargem:[],
        }
      };
    },
    methods: {
      closeModal (event) {
        if (event.target === event.currentTarget) {
          this.modalIsOpen = false;
          this.modalErroSalvar = false,
          this.modalError = false;
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      },
      async submit () {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          this.modalError = true;
          return
        }
        this.viewModel.Imagem = null;
        this.$http({
          url: "formaFarmaceutica/editar",
          data: this.viewModel,
          method: "POST"
        }).then((response) => {
           if(this.Imagem != "antiga") this.enviarImage(response.data)

            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({name:"farmaceuticas"}), 3000);
        }).catch(() => this.modalErroSalvar = true)
      },
      enviarImage (id) {
        let formData = new FormData();
        formData.append('file', this.Imagem);
        this.$http.post(`formaFarmaceutica/cad-image/${id}`, formData)
      },
      RecuperarForma (id) {
     this.$http({
      url: `/formaFarmaceutica/obter/${id}`,
      method: "Get"
    }).then((response) => {
        this.viewModel.TipoForma= response.data.tipoForma;
        this.viewModel.Descricao= response.data.descricao;
        this.viewModel.SelecionaQtdeSugerida= response.data.selecionaQtdeSugerida;
        this.viewModel.MultiplicaComposicao=response.data.multiplicaComposicao;
        this.viewModel.HomeopatiaLiquida=response.data.homeopatiaLiquida;
        this.viewModel.id = response.data.id;
        this.viewModel.DeduzirQtdeVeiculo=response.data.deduzirQtdeVeiculo
        this.viewModel.ConverteVolumeEmbalagem= response.data.converteVolumeEmbalagem;
        this.viewModel.CalculoEmbalagem= response.data.calculoEmbalagem;
        this.viewModel.Uso = response.data.uso;
        this.viewModel.InativarForma= response.data.inativarForma;
        this.viewModel.LabEspCustos= response.data.labEspCustos;
        this.previwImage = "data:image/jpeg;base64,".concat(response.data.imagem);
        this.Imagem = "antiga";
        this.viewModel.TipoUso= response.data.tipoUso;
        this.viewModel.ProcedimentoOperacionalPadrao= response.data.procedimentoOperacionalPadrao;
        this.viewModel.ImprimeCamposAnaliseProduto= response.data.imprimeCamposAnaliseProduto;
        this.viewModel.Validade= response.data.validade;
        this.viewModel.MlGotas= response.data.mlGotas;
        this.viewModel.ManipuladorID= response.data.manipuladorID;
        this.viewModel.QuantidadeFormulasHora= response.data.quantidadeFormulasHora;
        this.viewModel.FatorPerda=response.data.fatorPerda;
        this.viewModel.DescricaoRotulo= response.data.descricaoRotulo;
        this.viewModel.PercentualQspMinimo= response.data.percentualQspMinimo;
        this.viewModel.VeiculoID= response.data.veiculoID;
        this.viewModel.AtivaPesagemForma= response.data.ativaPesagemForma;
        this.viewModel.CalcularDensidade= response.data.calcularDensidade;
        this.viewModel.imprimirUnidadeRotulo= response.data.imprimirUnidadeRotulo;
        this.viewModel.SelecionaVolumeAutomatico=response.data.selecionaVolumeAutomatico;
        this.viewModel.AtivaFatorPerdaQSP= response.data.ativaFatorPerdaQSP;
        this.viewModel.FormaFarmaceuticaEnsaio= response.data.formaFarmaceuticaEnsaio;
        this.viewModel.ValorMinimoForma= response.data.valorMinimoForma;
        this.viewModel.CustoAdicionalForma= response.data.custoAdicionalForma;
        this.viewModel.NcmID= response.data.ncmID;
        this.viewModel.FormaFarmaceuticaFaixa= response.data.formaFarmaceuticaFaixa;
        this.viewModel.FormaFarmaceuticaMargem=response.data.formaFarmaceuticaMargem;
    })
   },
    },
    
    watch: {
      tabIndexComponent() {
        this.$emit("update:tabIndex", this.tabIndexComponent);
      },
    },
  };
  </script>
  